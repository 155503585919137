.page-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background-color: #FFF3D9;
  height: 9vh;
}

.page-footer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background-color: #BAA87F;
  height: 9vh;
}

@media only screen and (min-width: 400px) {

  .contact-list, .contact-list-footer {
      margin-right: 10px;
  }

  .navigation-list {
      margin-left: 60px;
  }
}

.dd-about-me, .dd-projects {
  display: block;
}