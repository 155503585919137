.home-section {
  margin: 1vh 0 1vh 0;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
}

.top-circle {
  background-image: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0)), url('../images/Chris+Steph2.jpg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  height: 200px;
  border-top-left-radius: 50%;
  border-top-right-radius: 50%;
  transition: .5s;
}

.bottom-circle {
  background-image: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0)), url('../images/MB_Landing.jpg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  height: 200px;
  border-bottom-right-radius: 50% 50%;
  border-bottom-left-radius: 50% 50%;
  transition: .5s;
}

.about-me{
  position: absolute;
  top: 0;
  left: 27%;
  right: auto;
  color: black;
  background: var(--beige);
  border-top-left-radius: 50%;
  border-top-right-radius: 50%;
  padding: 8px 30px 0 30px;
  box-shadow: 0px -2px 2px rgba(34,34,34,0.6);
  -moz-box-shadow: 1px 2px 3px rgba(0,0,0,.5);
  -webkit-box-shadow: 1px 2px 3px rgba(0,0,0,.5);
}

.projects{
  position: absolute;
  bottom: -17px;
  left: 27.5%;
  right: auto;
  color: black;
  background: var(--beige);
  border-bottom-right-radius: 50% 50%;
  border-bottom-left-radius: 50% 50%;
  padding: 0 35px 8px 35px;
  box-shadow: 0px -2px 2px rgba(34,34,34,0.6);
  -moz-box-shadow: 1px 2px 3px rgba(0,0,0,.5);
  -webkit-box-shadow: 1px 2px 3px rgba(0,0,0,.5);
}

.home-navigation {
  margin: auto;
}

.home-text {
  margin: 20px auto 0 auto;
  width: 300px;
}


.top-circle, .bottom-circle {
  width: 300px;
  border: 1px solid #BAA87F;
}

.grow:hover { 
  transform: scale(1.2);
  z-index: 1;
 }

@media only screen and (min-width: 400px) {

  .top-circle, .bottom-circle {
      width: 300px;
  }

  .home-text {
    margin: auto;
    width: 300px;
  }
}

@media only screen and (min-width: 600px) {
  .home-section {
    height: 80vh;
  }
}