.logo-name {
  display: flex;
  flex-direction: row;
}

.logo {
  height: 40px;
  margin: 2px 0 0 15px;
}

.contact-list, .navigation-list {
  margin-top: 10px;
}
